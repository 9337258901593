import React from 'react'
import StoreApi from '../Controllers/StoreApi';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CreateCustomer() {
    const { http} = StoreApi();
    const [customer, setCustomer] = useState({});
    const [order, setOrder] = useState({});
    const [invoice, setInvoice] = useState({});
    const navigate = useNavigate();

    const addCustomer = (firstName, lastName, companyName, zipCode, city, email, phone, address, finalAmount, totalQuantity, selectedPayment ,sfirstName, slastName, scompanyName, szipCode, scity, semail, sphone, saddress, same, couponCode, discount, shippingCharges ) => {
      if(same) {  
      http.post("/addCustomer", { 
           FirstName: firstName,
           LastName: lastName,
           CompanyName: companyName,
           ZipCode: zipCode,
           City: city,
           Email: email,
           Phone: phone,
           Address: address,
           s:same
          }, {
         })
          .then(response => {
            if (response.status === 200) {
              console.log('API response:', response.data);
              setCustomer(response.data);
              localStorage.setItem('cc', JSON.stringify(response.data.shipping));
              localStorage.setItem('ccc', JSON.stringify(response.data.customer));

              createOrder(finalAmount, totalQuantity, selectedPayment, couponCode, discount, shippingCharges)
              // navigate('/checkout/comfirmation');
              // window.location.href = '/checkout/confirmation';
            } else {
              console.error("Fetching Customer failed:", response.data.message);
            }
          })
          .catch(error => {
            console.error("Error during fetching Customer:", error.message);
            // localStorage.clear();
            // navigate('/login');
          })
          .finally(() => {
          
           
        });

      } else if(!same) {
        http.post("/addCustomer", { 
          FirstName: firstName,
          LastName: lastName,
          CompanyName: companyName,
          ZipCode: zipCode,
          City: city,
          Email: email,
          Phone: phone,
          Address: address,
          sFirstName: sfirstName,
          sLastName: slastName,
          sCompanyName: scompanyName,
          sZipCode: szipCode,
          sCity: scity,
          sEmail: semail,
          sPhone: sphone,
          sAddress: saddress,
          s:same
         }, {
        })
         .then(response => {
           if (response.status === 200) {
             console.log('API response:', response.data);
             setCustomer(response.data);
             localStorage.setItem('cc', JSON.stringify(response.data.shipping));
             localStorage.setItem('ccc', JSON.stringify(response.data.customer));
             createOrder(finalAmount, totalQuantity, selectedPayment, couponCode, discount, shippingCharges)
            //  navigate('/checkout/comfirmation');
            // window.location.href = '/checkout/confirmation';
           } else {
             console.error("Fetching Customer failed:", response.data.message);
           }
         })
         .catch(error => {
           console.error("Error during fetching Customer:", error.message);
           // localStorage.clear();
           // navigate('/login');
         })
         .finally(() => {
         
          
       });
      }
      }
    const createOrder = (finalAmount, totalQuantity, selectedPayment, couponCode, discount, shippingCharges) => {
     
       
      
      // console.log(finalAmount, totalQuantity);
        const customer = JSON.parse(localStorage.getItem('cc'));
        const customerId = customer.Id;
        const scustomer = JSON.parse(localStorage.getItem('ccc'));
        const scustomerId = scustomer.Id;
        const cart = JSON.parse(localStorage.getItem('cart'));
        const oN = localStorage.getItem('orderNumber');
        if (oN) {
          http.post('/updateOrder', { 
            cart: cart,
            cid: customerId,
            scid: scustomerId,
            finalAmount: finalAmount,
            totalQuantity: totalQuantity,
            paymentMethod: selectedPayment,
            shippingCharges : shippingCharges
          }, {
        })
        .then(response => {
            if (response.status === 200) {
              console.log('API response:', response.data);
              setOrder(response.data);
              localStorage.setItem('orderNumber', response.data.order.OrderNo);
            } else {
              console.error("Fetching Order failed:", response.data.message);
            }
            window.location.href = '/checkout/confirmation';

          })
          .catch(error => {
            console.error("Error during fetching Order:", error.message);
            // localStorage.clear();
            // navigate('/login');
          })
          .finally(() => {
          
           
        });
        }
        else {
        http.post('/createOrder', { 
            cart: cart,
            cid: customerId,
            scid: scustomerId,
            finalAmount: finalAmount,
            totalQuantity: totalQuantity,
            paymentMethod: selectedPayment,
            couponCode: couponCode,
            discount: discount,
            shippingCharges: shippingCharges
          }, {
        })
        .then(response => {
            if (response.status === 200) {
              console.log('API response:', response.data);
              setOrder(response.data);
              localStorage.setItem('orderNumber', response.data.order.OrderNo);
              // localStorage.setItem('ta', finalAmount);
              // localStorage.setItem('tq', totalQuantity);
              if (localStorage.getItem('oldorderNumber') !== null) {
                localStorage.removeItem('oldorderNumber');
              }
              if (localStorage.getItem('sc') !== null) {
                localStorage.removeItem('sc');
              }
              localStorage.setItem('sc', shippingCharges);
            } else {
              console.error("Fetching Order failed:", response.data.message);
            }
            window.location.href = '/checkout/confirmation';

          })
          .catch(error => {
            console.error("Error during fetching Order:", error.message);
            // localStorage.clear();
            // navigate('/login');
          })
          .finally(() => {
          
           
        });
      }
    }
  //   const checkOut = () => {
  //     const orderId = localStorage.getItem('orderNumber');
  //     if(orderId) { 
  //     console.log(orderId);
  //     http.post('/checkOut', { 
  //      orderId: orderId
  //     }, {
  //   })
  //   .then(response => {
  //       if (response.status === 200) {
  //         console.log('API response:', response.data);
  //         localStorage.removeItem('cart');
  //         if (localStorage.getItem('oldorderNumber') !== null) {
  //           localStorage.removeItem('oldorderNumber');
  //         }
  //         localStorage.setItem('oldorderNumber', orderId);
  //         localStorage.removeItem('orderNumber');

          


  //       } else {
  //         console.error("Fetching Order failed:", response.data.message);
  //       }
  //     })
  //     .catch(error => {
  //       console.error("Error during fetching Order:", error.message);
  //       // localStorage.clear();
  //       // navigate('/login');
  //     })
  //     .finally(() => {
      
       
  //   });      
  // }
  //   }

  const checkOut = () => {
    const orderId = localStorage.getItem('orderNumber');
    if (orderId) { 
      console.log(orderId);
      // Return the promise so it can be used in completeOrder()
      return http.post('/checkOut', { 
        orderId: orderId
      }, {
        // Any additional config here
      })
      .then(response => {
        if (response.status === 200) {
          console.log('API response:', response.data);
          localStorage.removeItem('cart');
          if (localStorage.getItem('oldorderNumber') !== null) {
            localStorage.removeItem('oldorderNumber');
          }
          localStorage.setItem('oldorderNumber', orderId);
          localStorage.removeItem('orderNumber');
          localStorage.removeItem('coupon_iv');
          localStorage.removeItem('coupon_encrypted');
          localStorage.removeItem('coupon_key');
          localStorage.removeItem('coupon_code');
          localStorage.removeItem('sc');
        } else {
          console.error("Fetching Order failed:", response.data.message);
        }
      })
      .catch(error => {
        console.error("Error during fetching Order:", error.message);
        // Handle error, e.g. clearing localStorage or redirecting to login
      })
      .finally(() => {
        // Any cleanup or final steps here
      });
    } else {
      return Promise.reject("No order ID found");
    }
  };
  
    const getInvoices = () => {

      const orderId = localStorage.getItem('oldorderNumber');
      if(orderId) { 
      console.log(orderId);
      http.post('/getOrderInvoice', { 
       orderId: orderId
      }, {
    })
    .then(response => {
        if (response.status === 200) {
          // console.log('API response:', response.data);
          setInvoice(response.data);

        } else {
          console.error("Fetching Order failed:", response.data.message);
        }
      })
      .catch(error => {
        console.error("Error during fetching Order:", error.message);
        // localStorage.clear();
        // navigate('/login');
      })
      .finally(() => {
      
       
    });      
  }
    return invoice;
    }

    const createLead = (name, email, message, phone) => {
      http.post('/createLead', {
        name: name,
        email: email,
        message: message,
        phone: phone,
      }, {
      }).then(response => {
        if (response.status === 200) {
          console.log('API response:', response.data);
        } else {
          console.error("Fetching Order failed:", response.data.message);
        }
      })
      .catch(error => {
        console.error("Error during fetching Order:", error.message);
        // localStorage.clear();
        // navigate('/login');
      })
      .finally(() => {
      
       
    });
    };
      return { addCustomer, customer, createOrder, order, checkOut , getInvoices,invoice, createLead  };
}
